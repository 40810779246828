



































import { Vue, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { formatBytes } from '@shared/util/file';
import { Textfield, Spinner, Icon } from '@components/UI';
import { ImageUploadErrors } from '@shared/config/errors';

@Component({
    components: { Textfield, Spinner, Icon }
})
export default class UploadView extends Vue {
    
    @Action
    public uploadImage: (any) => Promise<any>;

    private title: string = '';
    private content: string = '';
    private image: File | null = null;
    private preview: any = null;
    private filename: string = '';
    private filesize: string = '';

    private error: string = '';
    private loading: boolean = false;
    private success: boolean = false;

    private progress: number = 0;

    private onProgress(progressEvent) {
        this.progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
    }

    private async onSubmit() {
        if (this.title.length > 0 && this.image) {
            try {
                this.loading = true;

                var cnt: any = (this.content != '') ? this.content : null;

                const response = await this.uploadImage({ title: this.title, content: cnt, file: this.image, progressCallback: this.onProgress });
                if(!response.success)
                    this.error = response.error || 'An unknown error occurred.';
                else {
                    this.success = true;
                    this.error = '';
                }
                
                this.loading = false;
                
            } catch (error) {
                console.log(error);
                this.loading = false;
                this.error = ImageUploadErrors[error.errorCode] || error.error || 'An Unknown Error Ocurred';
            }
        }
    }

    private onFilesChanged(name, files: File[]) {
        this.image = files[0];
        this.filename = files[0].name;
        this.filesize = formatBytes(files[0].size, 3);

        const reader = new FileReader();
        reader.onload = (e: any) => this.preview = e.target!.result;
        reader.readAsDataURL(files[0]);
    }

}

